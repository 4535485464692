import React, { FC } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import * as styles from "./index.module.scss";

import { OptionalGatsbyImage } from "../optional-gatsby-image";

type Props = {
  image?: GatsbyNewsQueryFragment["thumbnail"];
  content?: JSX.Element;
};

export const Card: FC<Props> = (props) => {
  const { content, image } = props;

  const data: GatsbyCardComponentQuery = useStaticQuery(graphql`
    query CardComponent {
      noimage: file(relativePath: { eq: "news/noimage.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  return (
    <article className={styles.Card}>
      <div className={styles.Card_MediaWrapper}>
        {image?.gatsbyImageData ? (
          <GatsbyImage
            className={styles.Card_Media}
            image={image.gatsbyImageData}
            alt=""
          />
        ) : (
          <OptionalGatsbyImage data={data.noimage} alt="noimage" />
        )}
      </div>
      <div className={styles.Card_BodyWrapper}>
        <div className={styles.Card_Body}>{content}</div>
      </div>
    </article>
  );
};
