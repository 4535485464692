import React, { Fragment, useMemo } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import { formatDate } from "~utils";
import { Card } from "~components/utils/card";
import * as styles from "./index.module.scss";

export type News = Pick<
  GatsbyNewsQueryFragment,
  "slug" | "title" | "date" | "thumbnail"
>;

type Props = {
  news: News[];
};

export const NewsList: React.FC<Props> = ({ news }) => {
  const data = useStaticQuery(graphql`
    query NewsListComponent {
      noimage: file(relativePath: { eq: "news/noimage.png" }) {
        publicURL
      }
    }
  `);

  const list = useMemo(
    () =>
      news.map((value) => {
        const { slug, thumbnail, title, date } = value;
        if (!slug) return null;
        const formattedDate = formatDate(date, "YYYY.MM.DD");
        const content = (
          <>
            <p className={styles.NewsList_Data}>{formattedDate}</p>
            <h5 className={styles.NewsList_Title}>{title}</h5>
          </>
        );
        return (
          <Fragment key={slug}>
            <Link to={`/news/${slug}`} className={styles.NewsList_Link}>
              <div className={styles.NewsList_Card}>
                <Card
                  content={content}
                  image={thumbnail}
                  // media={thumbnail?.fixed?.src || data.noimage.publicURL}
                />
              </div>
            </Link>
          </Fragment>
        );
      }),
    [data.noimage.publicURL, news],
  );

  return <div className={styles.NewsList}>{list}</div>;
};
