import React from "react";
import { graphql } from "gatsby";

import { withContext } from "~context";
import { FadeInAnimation } from "~components/utils/fade-in-animation";
import { PageLayout } from "~components/templates/page-layout";
import { SectionHeader } from "~components/templates/section-header";
import { NewsList } from "~components/utils/news-list";
import * as styles from "./index.module.scss";

type Props = {
  data: GatsbyNewsPageQuery;
};

const IndexPage: React.FC<Props> = (props) => {
  const { data } = props;
  const news = data.allContentfulNews.nodes.map((value) => {
    return {
      slug: value.slug!,
      date: value.date!,
      title: value.title!,
      thumbnail: value.thumbnail,
    };
  });
  return (
    <PageLayout
      paddingPattern="fit-haeder"
      meta={{
        title: "ニュース",
        description: "株式会社Flatt Securityのニュース一覧のページです。",
      }}
    >
      <div className={styles.News_Wrapper}>
        <div className={styles.News}>
          <SectionHeader
            imageSrc={data?.file?.publicURL ?? undefined}
            text="ニュース"
          />
          <FadeInAnimation>
            <NewsList news={news} />
          </FadeInAnimation>
        </div>
      </div>
    </PageLayout>
  );
};

export default withContext(IndexPage);

export const query = graphql`
  fragment NewsQuery on ContentfulNews {
    id
    slug
    title
    date
    body {
      body
    }
    thumbnail {
      gatsbyImageData(width: 720)
    }
  }

  query NewsPage {
    allContentfulNews(sort: { fields: date, order: DESC }) {
      nodes {
        ...NewsQuery
      }
    }
    file(relativePath: { eq: "news.svg" }) {
      publicURL
    }
  }
`;
